@columns: 12;
@atom: (100% / @columns);

@break_lg1: 1249px;
@break_lg2: 1199px;
@break_lg3: 991px;
@break_md0: 945px;
@break_md1: 820px;
@break_md2: 767px;
@break_sm1: 660px;
@break_sm2: 575px;
@break_xs1: 420px;
@break_xs2: 359px;

@offset: 2.7%;
@offset_lg1: @offset;
@offset_lg2: @offset;
@offset_lg3: @offset;
@offset_md0: @offset;
@offset_md1: @offset;
@offset_md2: @offset;
@offset_sm1: @offset;
@offset_sm2: @offset;
@offset_xs1: @offset;
@offset_xs2: @offset;

@offset_one_side: (@offset / 2);
@offset_lg1_one_side: (@offset_lg1 / 2);
@offset_lg2_one_side: (@offset_lg2 / 2);
@offset_lg3_one_side: (@offset_lg3 / 2);
@offset_md0_one_side: (@offset_md0 / 2);
@offset_md1_one_side: (@offset_md1 / 2);
@offset_md2_one_side: (@offset_md2 / 2);
@offset_sm1_one_side: (@offset_sm1 / 2);
@offset_sm2_one_side: (@offset_sm2 / 2);
@offset_xs1_one_side: (@offset_xs1 / 2);
@offset_xs2_one_side: (@offset_xs2 / 2);

@fields: 30px;
@fields_lg3: 15px;
@fields_sm2: 7px;

.wrapper-full(){
    padding-left: @fields;
    padding-right: @fields;

    .lg3-block({
        padding-left: @fields_lg3;
        padding-right: @fields_lg3;
    });

    .sm2-block({
        padding-left: @fields_sm2;
        padding-right: @fields_sm2;
    });
}

.row-offsets(){
    margin-left: (@offset_one_side * -1);
    margin-right: (@offset_one_side * -1);
}

.col-offsets(@type){
    @{type}-left: @offset_one_side;
    @{type}-right: @offset_one_side;
}

.wrapper(){
    max-width: 1349px;
    margin: 0 auto;
    .wrapper-full();
}

.row-flex(){
    display: flex;
    flex-wrap: wrap;
    .row-offsets();
}

.row-float(){
    .row-offsets();
    .clearfix();
}

.row-ib(){
    .row-offsets();
}

.col(){
    box-sizing: border-box;
    word-wrap: break-word;
    .col-offsets(margin);
}

.col-float(){
    float: left;
}

.col-ib(){
    display: inline-block;
    vertical-align: top;
}

.col-padding(){
    word-wrap: break-word;
    .col-offsets(padding);
}

.size(@n){
    width: @atom * @n - @offset;
}

.size-lg1(@n){
    .lg1(width, @atom * @n - @offset_lg1);
}

.size-lg2(@n){
    .lg2(width, @atom * @n - @offset_lg2);
}

.size-lg3(@n){
    .lg3(width, @atom * @n - @offset_lg3);
}

.size-md0(@n){
    .md0(width, @atom * @n - @offset_md0);
}

.size-md1(@n){
    .md1(width, @atom * @n - @offset_md1);
}

.size-md2(@n){
    .md2(width, @atom * @n - @offset_md2);
}

.size-sm1(@n){
    .sm1(width, @atom * @n - @offset_sm1);
}

.size-sm2(@n){
    .sm2(width, @atom * @n - @offset_sm2);
}

.size-xs1(@n){
    .xs1(width, @atom * @n - @offset_xs1);
}

.size-xs2(@n){
    .xs2(width, @atom * @n - @offset_xs2);
}

.shift(@n){
    margin-left: @atom * @n + @offset_one_side;
    margin-right: @atom * @n + @offset_one_side;
}

.shift-left(@n){
    margin-left: @atom * @n + @offset_one_side;
}

.shift-right(@n){
    margin-right: @atom * @n + @offset_one_side;
}

.shift-padding(@n){
    padding-left: @atom * @n + @offset_one_side;
    padding-right: @atom * @n + @offset_one_side;
}

.shift-padding-left(@n){
    padding-left: @atom * @n + @offset_one_side;
}

.shift-padding-right(@n){
    padding-right: @atom * @n + @offset_one_side;
}

.shift-lg1(@n){
    .lg1(margin-left, @atom * @n + @offset_lg1_one_side);
    .lg1(margin-right, @atom * @n + @offset_lg1_one_side);
}

.shift-lg1-left(@n){
    .lg1(margin-left, @atom * @n + @offset_lg1_one_side);
}

.shift-lg1-right(@n){
    .lg1(margin-right, @atom * @n + @offset_lg1_one_side);
}

.shift-lg1-padding(@n){
    .lg1(padding-left, @atom * @n + @offset_lg1_one_side);
    .lg1(padding-right, @atom * @n + @offset_lg1_one_side);
}

.shift-lg1-padding-left(@n){
    .lg1(padding-left, @atom * @n + @offset_lg1_one_side);
}

.shift-lg1-padding-right(@n){
    .lg1(padding-right, @atom * @n + @offset_lg1_one_side);
}

.shift-lg2(@n){
    .lg2(margin-left, @atom * @n + @offset_lg2_one_side);
    .lg2(margin-right, @atom * @n + @offset_lg2_one_side);
}

.shift-lg2-left(@n){
    .lg2(margin-left, @atom * @n + @offset_lg2_one_side);
}

.shift-lg2-right(@n){
    .lg2(margin-right, @atom * @n + @offset_lg2_one_side);
}

.shift-lg2-padding(@n){
    .lg2(padding-left, @atom * @n + @offset_lg2_one_side);
    .lg2(padding-right, @atom * @n + @offset_lg2_one_side);
}

.shift-lg2-padding-left(@n){
    .lg2(padding-left, @atom * @n + @offset_lg2_one_side);
}

.shift-lg2-padding-right(@n){
    .lg2(padding-right, @atom * @n + @offset_lg2_one_side);
}

.shift-lg3(@n){
    .lg3(margin-left, @atom * @n + @offset_lg3_one_side);
    .lg3(margin-right, @atom * @n + @offset_lg3_one_side);
}

.shift-lg3-left(@n){
    .lg3(margin-left, @atom * @n + @offset_lg3_one_side);
}

.shift-lg3-right(@n){
    .lg3(margin-right, @atom * @n + @offset_lg3_one_side);
}

.shift-lg3-padding(@n){
    .lg3(padding-left, @atom * @n + @offset_lg3_one_side);
    .lg3(padding-right, @atom * @n + @offset_lg3_one_side);
}

.shift-lg3-padding-left(@n){
    .lg3(padding-left, @atom * @n + @offset_lg3_one_side);
}

.shift-lg3-padding-right(@n){
    .lg3(padding-right, @atom * @n + @offset_lg3_one_side);
}

.shift-md0(@n){
    .md0(margin-left, @atom * @n + @offset_md0_one_side);
    .md0(margin-right, @atom * @n + @offset_md0_one_side);
}

.shift-md0-left(@n){
    .md0(margin-left, @atom * @n + @offset_md0_one_side);
}

.shift-md0-right(@n){
    .md0(margin-right, @atom * @n + @offset_md0_one_side);
}

.shift-md0-padding(@n){
    .md0(padding-left, @atom * @n + @offset_md0_one_side);
    .md0(padding-right, @atom * @n + @offset_md0_one_side);
}

.shift-md0-padding-left(@n){
    .md0(padding-left, @atom * @n + @offset_md0_one_side);
}

.shift-md0-padding-right(@n){
    .md0(padding-right, @atom * @n + @offset_md0_one_side);
}

.shift-md1(@n){
    .md1(margin-left, @atom * @n + @offset_md1_one_side);
    .md1(margin-right, @atom * @n + @offset_md1_one_side);
}

.shift-md1-left(@n){
    .md1(margin-left, @atom * @n + @offset_md1_one_side);
}

.shift-md1-right(@n){
    .md1(margin-right, @atom * @n + @offset_md1_one_side);
}

.shift-md1-padding(@n){
    .md1(padding-left, @atom * @n + @offset_md1_one_side);
    .md1(padding-right, @atom * @n + @offset_md1_one_side);
}

.shift-md1-padding-left(@n){
    .md1(padding-left, @atom * @n + @offset_md1_one_side);
}

.shift-md1-padding-right(@n){
    .md1(padding-right, @atom * @n + @offset_md1_one_side);
}

.shift-md2(@n){
    .md2(margin-left, @atom * @n + @offset_md2_one_side);
    .md2(margin-right, @atom * @n + @offset_md2_one_side);
}

.shift-md2-left(@n){
    .md2(margin-left, @atom * @n + @offset_md2_one_side);
}

.shift-md2-right(@n){
    .md2(margin-right, @atom * @n + @offset_md2_one_side);
}

.shift-md2-padding(@n){
    .md2(padding-left, @atom * @n + @offset_md2_one_side);
    .md2(padding-right, @atom * @n + @offset_md2_one_side);
}

.shift-md2-padding-left(@n){
    .md2(padding-left, @atom * @n + @offset_md2_one_side);
}

.shift-md2-padding-right(@n){
    .md2(padding-right, @atom * @n + @offset_md2_one_side);
}

.shift-sm1(@n){
    .sm1(margin-left, @atom * @n + @offset_sm1_one_side);
    .sm1(margin-right, @atom * @n + @offset_sm1_one_side);
}

.shift-sm1-left(@n){
    .sm1(margin-left, @atom * @n + @offset_sm1_one_side);
}

.shift-sm1-right(@n){
    .sm1(margin-right, @atom * @n + @offset_sm1_one_side);
}

.shift-sm1-padding(@n){
    .sm1(padding-left, @atom * @n + @offset_sm1_one_side);
    .sm1(padding-right, @atom * @n + @offset_sm1_one_side);
}

.shift-sm1-padding-left(@n){
    .sm1(padding-left, @atom * @n + @offset_sm1_one_side);
}

.shift-sm1-padding-right(@n){
    .sm1(padding-right, @atom * @n + @offset_sm1_one_side);
}

.shift-sm2(@n){
    .sm2(margin-left, @atom * @n + @offset_sm2_one_side);
    .sm2(margin-right, @atom * @n + @offset_sm2_one_side);
}

.shift-sm2-left(@n){
    .sm2(margin-left, @atom * @n + @offset_sm2_one_side);
}

.shift-sm2-right(@n){
    .sm2(margin-right, @atom * @n + @offset_sm2_one_side);
}

.shift-sm2-padding(@n){
    .sm2(padding-left, @atom * @n + @offset_sm2_one_side);
    .sm2(padding-right, @atom * @n + @offset_sm2_one_side);
}

.shift-sm2-padding-left(@n){
    .sm2(padding-left, @atom * @n + @offset_sm2_one_side);
}

.shift-sm2-padding-right(@n){
    .sm2(padding-right, @atom * @n + @offset_sm2_one_side);
}

.shift-xs1(@n){
    .xs1(margin-left, @atom * @n + @offset_xs1_one_side);
    .xs1(margin-right, @atom * @n + @offset_xs1_one_side);
}

.shift-xs1-left(@n){
    .xs1(margin-left, @atom * @n + @offset_xs1_one_side);
}

.shift-xs1-right(@n){
    .xs1(margin-right, @atom * @n + @offset_xs1_one_side);
}

.shift-xs1-padding(@n){
    .xs1(padding-left, @atom * @n + @offset_xs1_one_side);
    .xs1(padding-right, @atom * @n + @offset_xs1_one_side);
}

.shift-xs1-padding-left(@n){
    .xs1(padding-left, @atom * @n + @offset_xs1_one_side);
}

.shift-xs1-padding-right(@n){
    .xs1(padding-right, @atom * @n + @offset_xs1_one_side);
}

.shift-xs2(@n){
    .xs2(margin-left, @atom * @n + @offset_xs2_one_side);
    .xs2(margin-right, @atom * @n + @offset_xs2_one_side);
}

.shift-xs2-left(@n){
    .xs2(margin-left, @atom * @n + @offset_xs2_one_side);
}

.shift-xs2-right(@n){
    .xs2(margin-right, @atom * @n + @offset_xs2_one_side);
}

.shift-xs2-padding(@n){
    .xs2(padding-left, @atom * @n + @offset_xs2_one_side);
    .xs2(padding-right, @atom * @n + @offset_xs2_one_side);
}

.shift-xs2-padding-left(@n){
    .xs2(padding-left, @atom * @n + @offset_xs2_one_side);
}

.shift-xs2-padding-right(@n){
    .xs2(padding-right, @atom * @n + @offset_xs2_one_side);
}

.lg1(@name, @value){
    @media screen and (max-width: @break_lg1){
        @{name}: @value;
    }
}

.lg1-block(@content){
    @media screen and (max-width: @break_lg1){
        @content();
    }
}

.lg2(@name, @value){
    @media screen and (max-width: @break_lg2){
        @{name}: @value;
    }
}

.lg2-block(@content){
    @media screen and (max-width: @break_lg2){
        @content();
    }
}

.lg3(@name, @value){
    @media screen and (max-width: @break_lg3){
        @{name}: @value;
    }
}

.lg3-block(@content){
    @media screen and (max-width: @break_lg3){
        @content();
    }
}

.md0(@name, @value){
    @media screen and (max-width: @break_md0){
        @{name}: @value;
    }
}

.md0-block(@content){
    @media screen and (max-width: @break_md0){
        @content();
    }
}

.md1(@name, @value){
    @media screen and (max-width: @break_md1){
        @{name}: @value;
    }
}

.md1-block(@content){
    @media screen and (max-width: @break_md1){
        @content();
    }
}

.md2(@name, @value){
    @media screen and (max-width: @break_md2){
        @{name}: @value;
    }
}

.md2-block(@content){
    @media screen and (max-width: @break_md2){
        @content();
    }
}

.sm1(@name, @value){
    @media screen and (max-width: @break_sm1){
        @{name}: @value;
    }
}

.sm1-block(@content){
    @media screen and (max-width: @break_sm1){
        @content();
    }
}

.sm2(@name, @value){
    @media screen and (max-width: @break_sm2){
        @{name}: @value;
    }
}

.sm2-block(@content){
    @media screen and (max-width: @break_sm2){
        @content();
    }
}

.xs1(@name, @value){
    @media screen and (max-width: @break_xs1){
        @{name}: @value;
    }
}

.xs1-block(@content){
    @media screen and (max-width: @break_xs1){
        @content();
    }
}

.xs2(@name, @value){
    @media screen and (max-width: @break_xs2){
        @{name}: @value;
    }
}

.xs2-block(@content){
    @media screen and (max-width: @break_xs2){
        @content();
    }
}

.from(@min_width, @content){
    @media screen and (min-width: @min_width){
        @content();
    }
}

.to(@max_width, @content){
    @media screen and (max-width: @max_width){
        @content();
    }
}

.from-to(@min_width, @max_width, @content){
    @media screen and (min-width: @min_width) and (max-width: @max_width){
        @content();
    }
}

.u-row-flex(@offset){
    display: flex;
    flex-wrap: wrap;
    margin-left: (@offset / -2);
    margin-right: (@offset / -2);
}

.u-col(@offset){
    box-sizing: border-box;
    word-wrap: break-word;
    margin-left: (@offset / 2);
    margin-right: (@offset / 2);
}

.u-size(@n, @columns, @offset){
    width: ~"calc(100% / @{columns} * @{n} - @{offset})";
}

.reset(){
    body,div,dl,dt,dd,ul,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,input,textarea,p,blockquote,th,td{
        margin: 0;
        padding: 0;
    }
    table{
        border-collapse: collapse;
        border-spacing: 0;
    }
    fieldset,img,abbr{
        border: 0;
    }
    address,caption,cite,code,dfn,em,strong,th,var{
        font-style: normal;
        font-weight: normal;
    }
    ul li{
        list-style: none;
    }
    caption,th{
        text-align: left;
    }
    h1,h2,h3,h4,h5,h6{
        font-size: 100%;
        font-weight: normal;
    }
    sup{
        vertical-align: text-top;
    }
    sub{
        vertical-align: text-bottom;
    }
    input,textarea,select{
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
    }
    legend{
        color: #000;
    }
    article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section,main{
        display: block;
    }
    img{
        max-width: 100%;
        height: auto;
    }
}

.debug(@background, @outline){
    height: 100vh;
    position: fixed;
    width: 100%;
    
    > div{
        height: inherit;
        .wrapper();
        
        > div{
            height: inherit;
            .row-flex();
            
            > div{
                .col();
                .size(1);
                height: inherit;
                outline: @outline;
                background: @background;
            }
        }
    }
}

.clearfix(){
    &::after{
        display: block;
        content: "";
        clear: both;
    }
}