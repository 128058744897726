.calc {
	max-width: 1140px;
}
.cheading {
	padding-top: 40px;
    margin-bottom: 50px;
    line-height: 48px;
    text-align: center;
    color: #444;
    font-family: 'Open Sans', sans-serif;
    h2 {
    	font-size: 32px;
	    margin-bottom: 0;
    	font-weight: 500;
    	letter-spacing: 1px;
    }
    .color {
	    color: #0078D7 !important;
    }
    .hsub {
    	display: block;
		margin-top: 10px;
	    font-weight: 300;
    	color: #2f2f2f;
    	font-size: 22px;
    }
	.xs1-block({
		padding-top: 20px;
	    margin-bottom: 30px;
	    line-height: 24px;
	    h2 {
	    	font-size: 22px;
	    	line-height: 26px;
	    	margin-bottom: 15px;
	    	letter-spacing: -0.5px;
	    }
	    .hsub {
    	font-size: 18px;
    }	    
    })	
}
.cheading:after {
    content: '';
    display: block;
    margin-top: 30px;
    width: 40px;
    border-top: 2px solid #444;
    margin: 25px auto 0;
}
.cbody {
	font-size: 15px;
    color: #2f2f2f;
    line-height: 22px;
    margin: 40px 0;
    background-color: #FFF;
    border: 4px double #a8a8a8;
    border-radius: 5px;
}
.sec1h, .sec2h, .sec3h, .sec1-1h, .sec4h {
    padding: 60px 0 10px 0;
    font-size: 21px;
    color: #0078D7;
    text-align: center;
    .xs1-block({
    	padding: 30px 10px 10px 10px;
    	font-size: 17px;
    	text-align: left;
    })
}

#selectType {
	display: flex;
	flex-wrap: wrap;
    justify-content: center;
	.sti {
		flex-basis: 33%;
	    
		img {
			padding-top: 20px;
		}
	}
}
#selectType2 {
	display: flex;
	flex-wrap: wrap;
    justify-content: center;
	.sti {
		flex-basis: 33%;
	    
		img {
			padding-top: 20px;
		}
	}
}
.n1, .n2, .n3, .n1-1, .n4 {
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
    margin: 30px 30px 0 30px;
    justify-content: center;
    .xs1-block({
		margin: 10px 15px 0 15px;
    })
}
.n1i {
    flex-basis: 140px;
    margin-right: 40px;
	text-align: left;
	justify-content: center;
	&:last-child {
		margin-right: 0px;
	}
	label {
		text-transform: uppercase;
		font-weight: 700;
		font-size: 13px;
		font-family: 'Open Sans', sans-serif;
	}
	img {
		padding-top: 10px;
	}
	.xs1-block({
	    flex-basis: 100%;
	    margin-right: 0px;
		label {
			font-size: 12px;
			span {
				white-space: nowrap;
			}
			img {
				max-width: 80px;
    			padding-left: 10px;
    		}
		}	    
    })
}
.n1-1i {
    flex-basis: 140px;
    margin-right: 40px;
	text-align: left;
	justify-content: center;
	&:last-child {
		margin-right: 0px;
	}
	label {
		text-transform: uppercase;
		font-weight: 700;
		font-size: 13px;
		font-family: 'Open Sans', sans-serif;
	}
	img {
		padding-top: 10px;
	}
	.xs1-block({
		flex-basis: calc(50% - 10px);
		margin-right: 10px;
		label {
			margin-bottom: 20px;
		}
		label img {
			max-width: 100px;
		}	    
    })
}
.n2i {
	flex-basis: 350px;
	margin-right: 50px;
	&:last-child {
		margin-right: 0px;
	}	
	.xs1-block({
	    flex-basis: 100%;
	    margin-right: 0px;
    })
}
.n2ii {
	padding-bottom: 30px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0;
    font-size: 14px;
    color: #555;
    font-weight: 700;
    text-transform: uppercase;
    .xs1-block({
		padding-bottom: 15px;
		font-size: 13px;
		line-height: 18px;
    })
}
.n3i, .n4i {
	flex-basis: 50%;
	.xs1-block({
		flex-basis: 100%;
		margin-bottom: 40px;
		label {
			font-size: 13px;
			line-height: 18px;
		}
    })
}
.white-section {
    max-width: 400px;
    margin: auto;
}
.sec1, .sec2, .sec1-1 {
	font-family: 'Open Sans', sans-serif;
	label {
		font-family: 'Open Sans', sans-serif;
	    letter-spacing: 0;
	}
}
.sec3, .sec4 {
	font-family: 'Open Sans', sans-serif;
	label {
		font-family: 'Open Sans', sans-serif;
	    letter-spacing: 0;
	    margin-bottom: 40px;
        font-size: 14px;
	}
}
.sec3 {
	.irs-bar {
		background-color: transparent;
	}
	.irs-min, .irs-max {
		display: none;
	}
}

.sec1 [type="radio"], .sec1-1 [type="radio"], .sec2 [type="radio"] {
    top: 2px;
    position: relative;
    margin-right: 2px;
}

.sec1 input:checked + span, .sec1-1 input:checked + span, .sec2 input:checked + span {
	text-decoration: underline;
}
.calc .form-control {
	box-sizing: border-box !important;
    max-width: 50% !important;
    margin: auto !important;
    text-align:center !important;
    .xs1-block({
		max-width: 100% !important;
    })
}
.calc button.calcbut {
    display: inline-block;
    position: relative;
    cursor: pointer;
    outline: none;
    white-space: nowrap;
    margin: 5px;
    padding: 0 30px;
    font-size: 17px;
    height: 45px;
    line-height: 45px;
    background-color: #0078D7;
    color: #FFF;
    font-weight: 400;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.5px;
    border: none;
    text-shadow: none;
    border-radius: 3px;
}

.calc .exe {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	border-top: 1px solid #EEE;
	margin: 60px 30px 40px 30px;
    padding-top: 60px;
	div:first-child {
		flex-basis: 230px;
		margin-right: 40px;
	}
	div:last-child {
		flex-grow: 1;
		text-align: left;
		padding: 0 0 20px 0;
		font-size: 32px;
		line-height: 50px;
		display: block;
	}
	.xs1-block({
		margin: 10px 20px 40px 20px;
		padding-top: 30px;
		div:first-child {
			margin-right: 0px;
			flex-basis: 100%;
			text-align: center;
		}
		div:last-child {
			padding: 20px 0 0 0;
		    font-size: 22px;
		    line-height: 30px;
		}		
    })	
}
.calc #fprice {
	color: #0078D7;
}

