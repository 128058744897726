/* Webfont: Circe-Light */@font-face {
    font-family: 'Circe-Light';
    src: url('../../fonts/CirceLight.eot');
    src: url('../../fonts/CirceLight.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/CirceLight.woff') format('woff'),
         url('../../fonts/CirceLight.ttf') format('truetype');
}
/* Webfont: Circe-Regular */@font-face {
    font-family: 'Circe-Regular';
    src: url('../../fonts/CirceRegular.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/CirceRegular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/CirceRegular.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/CirceRegular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
}
/* Webfont: Circe-Bold */@font-face {
    font-family: 'Circe-Bold';
    src: url('../../fonts/CirceBold.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/CirceBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/CirceBold.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/CirceBold.ttf') format('truetype');
}


/* Webfont: Lato-Black */@font-face {
    font-family: 'LatoWebBlack';
    src: url('../../fonts/Lato-Black.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/Lato-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/Lato-Black.woff2') format('woff2'), /* Modern Browsers */
         url('../../fonts/Lato-Black.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/Lato-Black.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}


/* Webfont: Lato-Bold */@font-face {
    font-family: 'LatoWebBold';
    src: url('../../fonts/Lato-Bold.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/Lato-Bold.woff2') format('woff2'), /* Modern Browsers */
         url('../../fonts/Lato-Bold.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/Lato-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}


/* Webfont: Lato-Hairline */@font-face {
    font-family: 'LatoWebHairline';
    src: url('../../fonts/Lato-Hairline.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/Lato-Hairline.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/Lato-Hairline.woff2') format('woff2'), /* Modern Browsers */
         url('../../fonts/Lato-Hairline.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/Lato-Hairline.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}


/* Webfont: Lato-Heavy */@font-face {
    font-family: 'LatoWebHeavy';
    src: url('../../fonts/Lato-Heavy.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/Lato-Heavy.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/Lato-Heavy.woff2') format('woff2'), /* Modern Browsers */
         url('../../fonts/Lato-Heavy.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/Lato-Heavy.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}


/* Webfont: Lato-Light */@font-face {
    font-family: 'LatoWebLight';
    src: url('../../fonts/Lato-Light.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/Lato-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/Lato-Light.woff2') format('woff2'), /* Modern Browsers */
         url('../../fonts/Lato-Light.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/Lato-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}


/* Webfont: Lato-Medium */@font-face {
    font-family: 'LatoWebMedium';
    src: url('../../fonts/Lato-Medium.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/Lato-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/Lato-Medium.woff2') format('woff2'), /* Modern Browsers */
         url('../../fonts/Lato-Medium.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/Lato-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}


/* Webfont: Lato-Regular */@font-face {
    font-family: 'LatoWeb';
    src: url('../../fonts/Lato-Regular.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/Lato-Regular.woff2') format('woff2'), /* Modern Browsers */
         url('../../fonts/Lato-Regular.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/Lato-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Semibold */@font-face {
    font-family: 'LatoWebSemibold';
    src: url('../../fonts/Lato-Semibold.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/Lato-Semibold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/Lato-Semibold.woff2') format('woff2'), /* Modern Browsers */
         url('../../fonts/Lato-Semibold.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/Lato-Semibold.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}


/* Webfont: Lato-Thin */@font-face {
    font-family: 'LatoWebThin';
    src: url('../../fonts/Lato-Thin.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/Lato-Thin.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/Lato-Thin.woff2') format('woff2'), /* Modern Browsers */
         url('../../fonts/Lato-Thin.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/Lato-Thin.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/fa-light-300.eot");
  src: url("../../fonts/fa-light-300.eot?#iefix") format("embedded-opentype"), url("../../fonts/fa-light-300.woff2") format("woff2"), url("../../fonts/fa-light-300.woff") format("woff"), url("../../fonts/fa-light-300.ttf") format("truetype"), url("../../fonts/fa-light-300.svg#fontawesome") format("svg"); }

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300; }

.fal {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.fa-comment-dots:before {
  content: "\f4ad"; }
.fa-map-marker-alt:before {
  content: "\f3c5"; } 


@font-face {
  font-family: 'font-icons';
  src:url('../../fonts/font-icons.eot');
  src:url('../../fonts/font-icons.eot') format('embedded-opentype'),
    url('../../fonts/font-icons.woff') format('woff'),
    url('../../fonts/font-icons.ttf') format('truetype'),
    url('../../fonts/font-icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'lined-icons';
  src:url('../../fonts/lined-icons.eot');
  src:url('../../fonts/lined-icons.eot') format('embedded-opentype'),
    url('../../fonts/lined-icons.woff') format('woff'),
    url('../../fonts/lined-icons.ttf') format('truetype'),
    url('../../fonts/lined-icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Simple-Line-Icons';
  src:url('../../fonts/Simple-Line-Icons.eot');
  src:url('../../fonts/Simple-Line-Icons.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Simple-Line-Icons.woff') format('woff'),
    url('../../fonts/Simple-Line-Icons.ttf') format('truetype'),
    url('../../fonts/Simple-Line-Icons.svg#Simple-Line-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"],
[class^="icon-line-"], [class*=" icon-line-"],
[class^="icon-line2-"], [class*=" icon-line2-"],
.side-header #primary-menu ul > li.sub-menu > a:after,
.docs-navigation ul ul li.current a:after {
  display: inline-block;
  font-family: 'font-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-line-"], [class*=" icon-line-"] { font-family: 'lined-icons'; }

[class^="icon-line2-"], [class*=" icon-line2-"] { font-family: 'Simple-Line-Icons'; }

.icon-line-check:before {
  content: "\e116";
}

.icon-cc-visa:before {
  content: "\ecf6";
}

.icon-cc-mastercard:before {
  content: "\ecf3";
}

.icon-line-arrow-up:before {
  content: "\e096";
}
.icon-angle-up:before {
  content: "\e7a6";
}
.icon-heart:before {
  content: "\ea74";
}
.icon-caret-down1:before {
  content: "\e77a";
}
.icon-line-arrow-right:before {
  content: "\e095";
}

.icon-line-minimize:before {
  content: "\e113";
}

.icon-line2-call-end:before {
  content: "\e048";
}

@font-face {
  font-family: 'et-line';
  src:url('../../fonts/et-line.eot');
  src:url('../../fonts/et-line.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/et-line.woff') format('woff'),
    url('../../fonts/et-line.ttf') format('truetype'),
    url('../../fonts/et-line.svg#et-line') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]:before {
  font-family: 'et-line';
  content: attr(data-icon);
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display:inline-block;
}


.icon-et-map, .icon-et-phone, .icon-et-clock, .icon-et-chat, .icon-et-hotairballoon {
  font-family: 'et-line';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display:inline-block;
}

.icon-et-map:before {
  content: "\e025";
}

.icon-et-clock:before {
  content: "\e055";
}

.icon-et-phone:before {
  content: "\e004";
}

.icon-et-chat:before {
  content: "\e049";
}

.icon-et-hotairballoon:before {
  content: "\e044";
}